/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import "./index.css";

const MissionPage = () => {
  const { web3, armysNFTct, gameContract, setLoading, loading } = useAuth()
  const [armys, setArmys] = useState([])
  const [maps, setMaps] = useState([])

  const [teamid, setTeamid] = useState([])
  const [fight, setFight] = useState(null)
  const [blood, setBlood] = useState(0)

  useEffect(() => {
    if (web3 && web3.active) {
      initArmys()
      initMaps()
    }
    // eslint-disable-next-line
  }, [armysNFTct, web3, gameContract, loading])

  const initArmys = async () => {
    setFight(await gameContract.methods.calcFightPower(web3.account).call())
    const nfts = await armysNFTct.methods.nftTemplateOfOwner(web3.account).call()
    setArmys(nfts.map(val => val))
  }

  const initMaps = async () => {
    const getmaps = []
    for (let i = 1; i <= 10; i++) {
      const mapdetail = await gameContract.methods.maps(i).call()
      mapdetail.mapid = i
      getmaps.push(mapdetail)
    }
    setMaps(getmaps)
  }

  const selectNFT = (tokenid, bloodnum) => {
    if (teamid.includes(Number(tokenid))) {
      setBlood(blood - Number(bloodnum))
      setTeamid(oldV => [...oldV.filter(x => x !== Number(tokenid))])
    } else {
      if (teamid.length + 1 > 4) {
        toast("Only 4 NFTs in 1 battle")
      } else {
        setTeamid(oldV => [...oldV, Number(tokenid)])
        setBlood(blood + Number(bloodnum))
      }
    }

  }

  const statBattle = async (mapid) => {
    if (teamid.length !== 4) {
      toast("Pick 4 NFTs please")
      return
    }
    setLoading(true)
    try {
      gameContract.methods.joinBattle(mapid, teamid).send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: Number(await gameContract.methods.joinBattle(mapid, teamid).estimateGas({ from: web3.account })) + 50000
      }).then((res) => {
        console.log(res)
        toast("Battle started, track the result at missions")
        setLoading(false)
        setTeamid([])
        setBlood(0)
      }).catch(() => {
        toast("Battle can not start")
        setLoading(false)
        setTeamid([])
        setBlood(0)
      })
    } catch (error) {
      toast("Battle can not start")
      setLoading(false)
    }

  }

  return (
    <React.Fragment>
      <main className="page-main">
        <div className="uk-grid" data-uk-grid>
          <div className="uk-width-2-3@l uk-width-3-3@m uk-width-3-3@s">
            <h3 className="uk-text-lead">Maps</h3>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              ssr={true} // means to render carousel on server-side.
              infinite={false}
              autoPlay={false}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerclassName="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListclassName="custom-dot-list-style"
              itemclassName="carousel-item-padding-40-px"

            >
              {maps.map((val, index) => (
                <div className="recommend-slide" key={`map-${index}`}>
                  <div className="recommend-slide__box">
                    <div className="recommend-slide__info">
                      <div className="recommend-slide__logo">{val.mapid}. {val.name}</div>
                      <div className="recommend-slide__intro">
                        <span>Duration: {val.duration} seconds</span>
                      </div>
                      <div className="recommend-slide__rating-and-price">
                        <div className="recommend-slide__rating">
                          <i className="ico_favourites"></i> Blood require: <span>{val.blood}</span>
                        </div>
                      </div>
                      <div className="recommend-slide__rating-and-price">
                        <div className="recommend-slide__rating">
                          <i className="ico_star"></i> Fight Power require: <span>{val.fightpower}</span>
                        </div>
                      </div>
                      <div className="recommend-slide__btn">
                        <button onClick={() => statBattle(val.mapid)} className="uk-button uk-button-danger">Battle Now</button>
                      </div>
                      <div className="recommend-slide__platform">
                        {val.rewardType === "1" ? `Reward: ${web3.library.utils.fromWei(val.rewardAmount)} BNB` : `Reward: ${val.rewardAmount} shrapnels`}
                      </div>
                    </div>
                    <div className="recommend-slide__img">
                      <img src={`/assets/map/${val.mapid}.png`} width="100%" alt="" />
                    </div>
                    {/* <div className="recommend-slide__favorites"><i className="ico_favourites"></i></div> */}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="uk-width-1-3@l uk-width-3-3@m uk-width-3-3@s">
            <h3 className="uk-text-lead">Your stats</h3>
            <div>
              <div className="user-item --active">
                <div className="user-item__box">

                  <div className="user-item__playing">
                    Fight Power: <b>{fight && fight.fightpower}</b>
                  </div>
                  <div className="user-item__playing">
                    Team Blood: <b>{blood}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1">
            <h3 className="uk-text-lead">List NFT</h3>
            <Carousel swipeable={true} draggable={true} showDots={false}
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 5,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 3,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              slidesToSlide={2}
              infinite={false}
              autoPlay={false}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerclassName="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListclassName="custom-dot-list-style"
              itemclassName="carousel-item-padding-40-px"

            >
              {
                armys.sort((a, b) => Number(b.blood) - Number(a.blood)).map((val, index) => (
                  <div className={"game-card"}>
                    <div className={`game-card__box ${teamid.includes(Number(val.tokenid)) ? "nft-selected" : ""}`} onClick={() => selectNFT(Number(val.tokenid), Number(val.blood))}>
                      <div className="game-card__media">
                        <img src={`/assets/nft/army/${val.tier}.png`} alt="" />
                      </div>
                      <div className="game-card__info">
                        {/* eslint-disable-next-line */}
                        <a className="game-card__title">
                          #{val.tokenid} {val.tier === "1" ? "SOLDIER" : val.tier === "2" ? "SWAT" : val.tier === "3" ? "TANK" : val.tier === "4" ? "AIRCRAFT" : ""}
                        </a>
                        <div className="game-card__rating-and-price">
                          <div className="game-card__rating">
                            <i className="ico_favourites"></i>
                          </div>
                          <div className="game-card__price">
                            <span>{val.blood}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default MissionPage;
