import "./market.css";
import React from "react";
import Card from "./card";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import CartridgeCard from "./cartridge";

export default function MarketPage() {
  const { web3, setLoading, gameContract, tokenct } = useAuth()

  const initPrice = async (count, paytype, nfttype) => {
    const prices = await gameContract.methods.getPrice(count, paytype, nfttype).call()
    return prices
  }

  const mintNft = async (count, paytype, nfttype) => {
    const isopen = await gameContract.methods.isOpen().call()
    if (isopen) {
      setLoading(true)
      try {
        const prices = await initPrice(count, paytype, nfttype)
        gameContract.methods.mintNFTArmy(paytype, count, nfttype, false).send({
          from: web3.account,
          gasPrice: 10000000000,
          value: prices.bnb_,
          gas: await gameContract.methods.mintNFTArmy(paytype, count, nfttype, false).estimateGas({ from: web3.account, value: prices.bnb_, })
        }).then(() => {
          toast("Mint NFT successfull");
          setLoading(false);
        }).catch(() => {
          toast("Mint NFT failed");
          setLoading(false);
        });
      } catch (error) {
        toast("Mint NFT failed");
        setLoading(false);
      }
    } else {
      setLoading(false)
      toast("Can not mint NFT right now")
    }

  };

  const getMintStatus = async (nfttype) => {
    const maxcount = await gameContract.methods.mintArmyMaxCount(Number(nfttype) - 1).call()
    const minted = await gameContract.methods.armyNumMinted(nfttype).call()
    return [minted, maxcount]
  }

  const renderCardItem = (item) => {
    return (
      <Card
        image={item.image}
        title={item.title}
        web3={web3}
        nfttype={item.type}
        initPrice={initPrice}
        mintNft={mintNft}
        mintStatus={getMintStatus}
      />
    );
  };

  const renderCartridgeItem = (item) => {
    return (
      <CartridgeCard
        image={item.image}
        title={item.title}
        web3={web3}
        setLoading={setLoading}
        gameContract={gameContract}
        tokenct={tokenct}
      />
    );
  };

  return (
    <React.Fragment>
      <main className="page-main">
        <div className="uk-grid" data-uk-grid>
          <div className="uk-width-3-3@l">
            <div className="widjet --filters">
              <div className="widjet__head">
                <h3 className="uk-text-lead">Mint Army NFT</h3>
                <a href="https://testnet.binance.org/faucet-smart" target="_blank">Get BNB testnet</a>
              </div>
            </div>
            <div className="widjet --market">{
              renderCardItem({
                image: "/assets/nft/army/1.png",
                title: "SOLDIER",
                type: 1
              })}
            </div>
            <div className="widjet --market">{
              renderCardItem({
                image: "/assets/nft/army/2.png",
                title: "SWAT",
                type: 2
              })}
            </div>
          </div>

          <div className="uk-width-3-3@l">
            <div className="widjet --filters">
              <div className="widjet__head">
                <h3 className="uk-text-lead">Cartridge</h3>
              </div>
            </div>
            <div className="widjet --market">{
              renderCartridgeItem({
                image: "/assets/nft/items/cartridge.png",
                title: "CARTRIDGE",
                type: 1
              })}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
